import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

import { PreviewImg } from 'volto-dlr/helpers';

const FileImageListingTemplate = ({
  items,
  linkTitle,
  linkHref,
  isEditMode,
}) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  return (
    <>
      <div className="file-listing-image items">
        {items.map((item) => (
          <div className="listing-item" key={item['@id']}>
            <ConditionalLink item={item} condition={!isEditMode}>
              <div className="image-wrapper">
                <PreviewImg
                  previewImage={item}
                  blurhashOptions={{
                    style: {
                      width: '100%',
                      height: 'auto',
                      alignSelf: 'center',
                    },
                  }}
                />
              </div>
              <div className="listing-body">
                <h2>{item.title ? item.title : item.id}</h2>
                {item && item['@type'] === 'File' && (
                  <div className="download-file">
                    {item?.getObjSize && item.getObjSize} |{' '}
                    {(() => {
                      switch (item?.mime_type) {
                        case 'image/jpeg':
                          return 'JPEG';
                        case 'image/png':
                          return 'PNG';
                        case 'image/svg+xml':
                          return 'SVG';
                        case 'image/gif':
                          return 'GIF';
                        case 'application/pdf':
                          return 'PDF';
                        case 'application/msexcel':
                          return 'XLS';
                        case 'application/vnd.ms-excel':
                          return 'XLS';
                        case 'application/msword':
                          return 'DOC';
                        case 'application/mspowerpoint':
                          return 'PPT';
                        case 'audio/mp4':
                          return 'MP4';
                        case 'application/zip':
                          return 'ZIP';
                        case 'video/webm':
                          return 'WEBM';
                        case 'video/x-msvideo':
                          return 'AVI';
                        case 'video/x-sgi-movie':
                          return 'MOVIE';
                        case 'text/xml':
                          return 'XML';
                        case 'text/plain':
                          return 'TXT';
                        case 'text/calendar':
                          return 'ICS';
                        case 'image/x-icon':
                          return 'ICO';
                        case 'image/bmp':
                          return 'BMP';
                        case 'audio/mpeg':
                          return 'MP3';
                        case 'audio/wav':
                          return 'WAV';
                        case 'application/json':
                          return 'JSON';
                        case 'application/postscript':
                          return 'PS';
                        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                          return 'XLSX';
                        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                          return 'DOCX';
                        case 'application/xml':
                          return 'XML';
                        case 'application/mshelp':
                          return 'HLP';
                        case 'application/gzip':
                          return 'GZ';
                        default:
                          return '';
                      }
                    })()}{' '}
                  </div>
                )}
                <p>{item.description}</p>
              </div>
            </ConditionalLink>
          </div>
        ))}
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};

FileImageListingTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default FileImageListingTemplate;
