import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { UniversalLink } from '@plone/volto/components';
import { FormattedDate } from '@plone/volto/components';
import { PreviewImg } from 'volto-dlr/helpers';
import { useTags } from 'volto-dlr/helpers';

import { getAuthors } from 'volto-dlr/actions/blog/authors';

/**
 * PostView view component class.
 * @function PostView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PostView = ({ content }) => {
  const dispatch = useDispatch();
  let location = useLocation();

  const pathname = location.pathname;
  useEffect(() => {
    dispatch(getAuthors(pathname));
  }, [dispatch, pathname]);
  const authors = useSelector((state) => state.authors);

  const tags = useTags();

  return (
    <div id="page-document" className="ui container view-wrapper blogpost-view">
      <header className="post head-title">
        <span className="day">
          <FormattedDate
            className="day"
            date={content?.effective}
            format={{
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }}
          />
        </span>
        {content?.research_domains?.length > 0 && ' | '}
        {content?.research_domains.map((domain, index) => (
          <span key={domain}>
            <span>{domain['title']}</span>
            {index < content?.research_domains.length - 1 && ', '}
          </span>
        ))}
      </header>
      <RenderBlocks content={content} />
      <div className="blog-footer">
        <div className="footer-wrapper">
          {content?.blog_tags.length >= 1 && (
            <div className="tags">
              <p>
                <strong>Tags:</strong>
              </p>
              <div className="tag-wrapper">
                {content?.blog_tags.map((obj) =>
                  tags[obj.token] ? (
                    <UniversalLink
                      className="ui button tag"
                      href={tags[obj.token].url}
                      key={obj.token}
                    >
                      {tags[obj.token].title}
                    </UniversalLink>
                  ) : null,
                )}
              </div>
            </div>
          )}
          {authors?.items?.map(
            (author) =>
              author['@type'] === 'Author' && (
                <div key={author['@id']}>
                  <div className="separator-wrapper">
                    <div className="line"></div>
                  </div>
                  <div className="about-the-author">
                    <div className="heading-wrapper">
                      <h2 className="heading">
                        <FormattedMessage id="About" defaultMessage="About" />
                        {` ${author?.fullname}`}
                      </h2>
                    </div>
                    <figure className="author-profile">
                      <div>
                        {author?.image_scales?.preview_image_link ? (
                          <PreviewImg
                            previewImage={author}
                            imageField="preview_image_link"
                            className="headshot"
                            alt={author.fullname}
                            width="150"
                            height="150"
                          />
                        ) : null}
                        <div>
                          <figcaption>
                            <p className="description">{`${author?.description} `}</p>
                          </figcaption>
                          <UniversalLink href={author['@id']}>
                            <button className="ui button">Mehr</button>
                          </UniversalLink>
                        </div>
                      </div>
                    </figure>
                  </div>
                </div>
              ),
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PostView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default PostView;
