/**
 * OVERRIDE View.jsx
 * REASON: Add kicker above the title, Add soft-hyphen support
 * FILE: https://raw.githubusercontent.com/plone/volto/59a5b3b147f501cfa44087019d947d7840b51f71/src/components/manage/Blocks/Title/View.jsx
 * FILE VERSION: Volto 16.0.0-alpha.34
 * DATE: 2022-09-20
 * DEVELOPER: @davisagli, @robgietema
 */

/**
 * View title/description block.
 * @module volto-slate/blocks/Title/TitleBlockView
 */

import { UniversalLink } from '@plone/volto/components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Kicker from 'volto-dlr/components/Blocks/Title/Kicker';
/* BEGIN CUSTOMIZATION */
import { softHyphenSupport } from 'volto-dlr/helpers';
/* END CUSTOMIZATION */

/**
 * View title block component.
 * @class View
 * @extends Component
 */
const TitleBlockView = ({ content, properties, metadata }) => {
  const authors = (content?.authors?.items ?? []).filter(
    (item) => item['@type'] === 'Author',
  );

  return (
    <>
      {/* BEGIN CUSTOMIZATION */}
      {content?.['@type'] === 'Post' ? (
        <>
          <Kicker properties={properties} />
          <div className="block title">
            <h1 className="documentFirstHeading">
              {softHyphenSupport((metadata || properties)['title'] || '')}
            </h1>
            {authors?.length >= 1 && (
              <>
                <FormattedMessage id="by" defaultMessage="by" />
                {authors?.map((author, index) => (
                  <>
                    <UniversalLink href={author['@id']}>
                      {` ${author.fullname}`}
                    </UniversalLink>
                    {index < authors?.length - 1 && ', '}
                  </>
                ))}
              </>
            )}
          </div>
        </>
      ) : content?.['@type'] === 'Author' ? (
        <>
          <Kicker properties={properties} />
          <div className="block title">
            <h1 className="documentFirstHeading">
              <FormattedMessage id="About" defaultMessage="About" />
              {` ${softHyphenSupport((metadata || properties)['title'] || '')}`}
            </h1>
          </div>
        </>
      ) : (
        <>
          <Kicker properties={properties} />
          <h1 className="documentFirstHeading">
            {softHyphenSupport((metadata || properties)['title'] || '')}
          </h1>
        </>
      )}
      {/* END CUSTOMIZATION */}
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
TitleBlockView.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
  metadata: PropTypes.objectOf(PropTypes.any),
};

export default TitleBlockView;
