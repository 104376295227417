import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import config from '@plone/volto/registry';

export const PreviewImg = ({
  previewImage,
  href = null,
  imageField = null,
  scaleName = 'preview',
  alt = '',
  loading = 'lazy',
  defaultImage = DefaultImageSVG,
  ...props
}) => {
  const Img = config.getComponent('Img').component;
  const src = previewImage || href;
  imageField = imageField || src?.image_field;
  const key = imageField || defaultImage;
  const imageInfo = src?.image_scales?.[imageField]?.[0];
  return (
    <Img
      src={imageField ? imageInfo?.base_path || src?.['@id'] : null}
      key={key}
      width="1440"
      height="810"
      alt={alt}
      loading={loading}
      scales={imageInfo?.scales}
      defaultScale={scaleName}
      blurhash={imageInfo?.blurhash}
      placeholder={<img alt={alt} src={defaultImage} />}
      {...props}
    />
  );
};
