import React, { useState, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import loadable from '@loadable/component';
import { useSelector, useDispatch } from 'react-redux';
import { createContent } from '@plone/volto/actions';
import { readAsDataURL } from 'promise-file-reader';
import cx from 'classnames';
import { Icon, UniversalLink } from '@plone/volto/components';
import { getBaseUrl, usePrevious } from '@plone/volto/helpers';
import { Img } from '@kitconcept/volto-blocks/components';
import { TextBlockView } from '@plone/volto-slate/blocks/Text';
import { DetachedTextBlockEditor } from '@plone/volto-slate/blocks/Text/DetachedTextBlockEditor';
import HeadingEdit from './HeadingEdit';
import { softHyphenSupport } from 'volto-dlr/helpers';

import {
  Message,
  Container,
  Button,
  Dimmer,
  Input,
  Loader,
} from 'semantic-ui-react';

import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';
import aheadSVG from '@plone/volto/icons/ahead.svg';
import uploadSVG from '@plone/volto/icons/upload.svg';

const Dropzone = loadable(() => import('react-dropzone'));

const messages = defineMessages({
  ImageBlockInputPlaceholder: {
    id: 'Browse the site, drop an image, or type an URL',
    defaultMessage: 'Browse the site, drop an image, or type an URL',
  },
});

const HighlightView = (props) => {
  const { className, data, isEditMode } = props;
  const intl = useIntl();
  const [uploading, setUploading] = useState(false);
  const [dragging, setDragging] = useState(false);
  const dispatch = useDispatch();

  const placeholder = intl.formatMessage(messages.ImageBlockInputPlaceholder);
  let loading = '';

  const request = useSelector(
    (state) => state.content.subrequests[props.block],
  );
  const content = useSelector(
    (state) => state.content.subrequests[props.block]?.data,
  );

  const lang = useSelector((state) => state.intl.locale);

  const alt = props.properties.title;
  const urlUploaded = content ? content['@id'] : null;

  const buttonLink = data?.buttonLink?.[0] ? data?.buttonLink[0]['@id'] : '';

  // copy variable name for the useEffect dependency
  const onChangeBlockCopy = props.onChangeBlock;
  const blockCopy = props.block;
  const requestLoaded = request ? request.loaded : null;

  useEffect(() => {
    if (loading) {
      if (loading && requestLoaded && uploading) {
        setUploading(false);
        onChangeBlockCopy(blockCopy, {
          ...data,
          url: urlUploaded,
          alt: alt,
        });
      }
    }
  }, [
    requestLoaded,
    loading,
    uploading,
    urlUploaded,
    dispatch,
    alt,
    onChangeBlockCopy,
    blockCopy,
    data,
  ]);

  loading = usePrevious(request?.loading);

  /**
   * Upload image handler
   * not powered by react-dropzone
   * @method onUploadImage
   * @returns {undefined}
   */
  const onUploadImage = (e) => {
    e.stopPropagation();
    const file = e.target.files[0];
    setUploading(true);
    readAsDataURL(file).then((data) => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      dispatch(
        createContent(
          getBaseUrl(props.pathname),
          {
            '@type': 'Image',
            title: file.name,
            image: {
              data: fields[3],
              encoding: fields[2],
              'content-type': fields[1],
              filename: file.name,
            },
          },
          props.block,
        ),
      );
    });
  };

  /**
   * Submit url handler
   * @method onSubmitUrl
   * @param {object} e Event
   * @returns {undefined}
   */
  const onSubmitUrl = () => {
    props.onChangeBlock(props.block, {
      ...data,
      url: props.url,
    });
  };

  /**
   * Drop handler
   * @method onDrop
   * @param {array} files File objects
   * @returns {undefined}
   */
  const onDrop = (file) => {
    setUploading(true);
    readAsDataURL(file[0]).then((data) => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      dispatch(
        createContent(
          getBaseUrl(props.pathname),
          {
            '@type': 'Image',
            title: file[0].name,
            image: {
              data: fields[3],
              encoding: fields[2],
              'content-type': fields[1],
              filename: file[0].name,
            },
          },
          props.block,
        ),
      );
    });
  };

  const onDragEnter = () => {
    setDragging(true);
  };

  const onDragLeave = () => {
    setDragging(false);
  };

  /**
   * Keydown handler on Variant Menu Form
   * This is required since the ENTER key is already mapped to a onKeyDown
   * event and needs to be overriden with a child onKeyDown.
   * @method onKeyDownVariantMenuForm
   * @param {Object} e Event object
   * @returns {undefined}
   */
  const onKeyDownVariantMenuForm = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      onSubmitUrl();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      // TODO: Do something on ESC key
    }
  };

  let fullWidth = true;
  let viewPortWidth = false;
  if (data.width === 'default') {
    fullWidth = true;
  }
  if (data.width === 'narrow') {
    fullWidth = false;
  }
  if (data.width === '100%') {
    fullWidth = false;
    viewPortWidth = true;
  }

  // migrate description implicitly to richtext in edit mode
  if (isEditMode && data.description && !data.description.data) {
    props.onChangeBlock(props.block, {
      ...data,
      description: {
        data: `<p>${data.description}</p>`,
        'content-type': 'text/html',
      },
    });
  }

  const credit = data?.credit?.data;
  const creditIsEmpty =
    !credit || credit.replace(/<[^>]*>?/gm, '').match(/^\s*$/);

  const renderedCredit = creditIsEmpty ? (
    <UniversalLink
      tabIndex="-1"
      href={lang === 'de' ? '/de/service/impressum' : '/en/service/imprint'}
    >
      DLR (CC BY-NC-ND 3.0)
    </UniversalLink>
  ) : (
    <div
      dangerouslySetInnerHTML={{
        __html: credit,
      }}
    />
  );

  return (
    <div
      className={cx('block highlight', className, {
        'ui container': viewPortWidth,
      })}
    >
      {data.url ? (
        <div
          className={cx(
            'teaser-item top',
            data?.aspectRatio,
            data?.variation !== 'default' ? data?.variation : '',
          )}
        >
          <div className="highlight-image-wrapper">
            <Img
              style={{ height: data.height }}
              className={cx({
                'full-viewport-width': viewPortWidth,
              })}
              src={data.url}
              defaultScale={viewPortWidth ? 'huge' : 'great'}
              scales={data.image_scales?.image?.[0]?.scales}
              blurhash={data.image_scales?.image?.[0]?.blurhash}
              blurhashOptions={{ style: { width: '100%', height: 'auto' } }}
              /* Width and height hints MUST match aspect-ratio 1.77777778 defined in CSS */
              width="1440"
              height="810"
              alt=""
            />
            {data.showCredit && (
              <div className="highlight-credit">{renderedCredit} </div>
            )}
          </div>
          <div className={cx('highlight-description')}>
            <Container
              className={cx('teaser-description-title', {
                'default-size': !fullWidth,
              })}
            >
              <div className="title">
                {isEditMode ? (
                  <>
                    <HeadingEdit
                      {...props}
                      renderTag="span"
                      renderClassName="supertitle"
                      fieldDataName="head_title"
                      properties={{ title: data.head_title, isHeadtitle: true }}
                    />
                    <HeadingEdit
                      {...props}
                      renderTag="h2"
                      renderClassName=""
                      fieldDataName="title"
                      properties={{ title: data.title }}
                    />
                  </>
                ) : (
                  <>
                    {data?.head_title && (
                      <span className="supertitle">
                        {softHyphenSupport(data?.head_title)}
                      </span>
                    )}
                    {data?.title && <h2>{softHyphenSupport(data?.title)}</h2>}
                  </>
                )}
              </div>
              <div className="description">
                {isEditMode ? (
                  <DetachedTextBlockEditor {...props} />
                ) : (
                  <TextBlockView {...props} />
                )}
              </div>
              {data?.button && (
                <div className="button">
                  <Button inverted basic as="a" href={buttonLink}>
                    {softHyphenSupport(data?.buttonText)}
                  </Button>
                </div>
              )}
            </Container>
          </div>
        </div>
      ) : (
        <div>
          {props.isEditMode && (
            <Dropzone
              noClick
              onDrop={onDrop}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              className="dropzone"
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <Message>
                    {dragging && <Dimmer active></Dimmer>}
                    {uploading && (
                      <Dimmer active>
                        <Loader indeterminate>Uploading image</Loader>
                      </Dimmer>
                    )}
                    <div className="no-image-wrapper">
                      <img
                        className="placeholder-image"
                        src={imageBlockSVG}
                        alt=""
                      />
                      <div className="toolbar-inner">
                        <Button.Group>
                          <Button
                            basic
                            icon
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              props.openObjectBrowser({
                                onSelectItem: props.onSelectItem,
                              });
                            }}
                          >
                            <Icon name={navTreeSVG} size="24px" />
                          </Button>
                        </Button.Group>
                        <Button.Group>
                          <label className="ui button basic icon">
                            <Icon name={uploadSVG} size="24px" />
                            <input
                              {...getInputProps({
                                type: 'file',
                                onChange: onUploadImage,
                                style: { display: 'none' },
                              })}
                            />
                          </label>
                        </Button.Group>
                        <Input
                          onKeyDown={onKeyDownVariantMenuForm}
                          onChange={props.onChangeUrl}
                          placeholder={placeholder}
                          value={props.url}
                          onClick={(e) => {
                            e.target.focus();
                          }}
                          onFocus={(e) => {
                            props.onSelectBlock(props.id);
                          }}
                        />
                        {props.url && (
                          <Button.Group>
                            <Button
                              basic
                              className="cancel"
                              onClick={(e) => {
                                e.stopPropagation();
                                props.resetSubmitUrl();
                              }}
                            >
                              <Icon name={clearSVG} size="30px" />
                            </Button>
                          </Button.Group>
                        )}
                        <Button.Group>
                          <Button
                            basic
                            primary
                            disabled={!props.url}
                            onClick={(e) => {
                              e.stopPropagation();
                              onSubmitUrl();
                            }}
                          >
                            <Icon name={aheadSVG} size="30px" />
                          </Button>
                        </Button.Group>
                      </div>
                    </div>
                  </Message>
                </div>
              )}
            </Dropzone>
          )}
        </div>
      )}
    </div>
  );
};

export default HighlightView;
