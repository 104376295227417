/**
 * View video block.
 * @module components/manage/Blocks/Video/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import Video from '@plone/volto/components/manage/Blocks/Video/Body';
import cx from 'classnames';
import Caption from 'volto-dlr/components/Image/Caption';

/**
 * View video block class.
 * @class View
 * @extends Component
 */
const View = ({ data, className }) => {
  return (
    <div
      className={cx(
        'block video align',
        className,
        {
          center: !Boolean(data.align),
        },
        data.align,
      )}
    >
      {data.target_url && (
        <figure className="video-inner">
          <Video data={data} />
          <Caption
            title={data.title}
            description={data.description}
            credit={data.credit?.data}
            shows_people={data.shows_people}
          />
        </figure>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
