import { createRef, useState } from 'react';
import cx from 'classnames';
import { isInternalURL, flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink, MaybeWrap } from '@plone/volto/components';
import { FormattedMessage } from 'react-intl';
import { Message, Grid } from 'semantic-ui-react';
import { PreviewImg } from 'volto-dlr/helpers';
import { useCookies } from 'react-cookie';
import config from '@plone/volto/registry';
import CookieConsentButtons from '../../../components/CookieConsent/CookieConsentButtons';
import Embed from '../../../components/Embed/Embed';
import fallbackVideoImage from './das-dlr-video-fallback.jpg';

const DasDLRBlockView = (props) => {
  const { className, data, isEditMode } = props;
  const [active, setActive] = useState(false);
  const [cookies] = useCookies();
  const { openExternalLinkInNewTab } = config.settings;

  let placeholder;
  if (data.video_image?.[0]) {
    const image = data.video_image[0].image_scales.image[0];
    placeholder = `${flattenToAppURL(data.video_image[0]['@id'])}/${
      image.scales.larger.download || image.download
    }`;
  }

  let VideoProvider;
  if (data.video?.match('youtu')) {
    VideoProvider = 'youtube';
  } else if (data.video?.match('vimeo')) {
    VideoProvider = 'vimeo';
  } else if (
    data.video?.match('quickchannel') ||
    data.video?.match('screen9')
  ) {
    VideoProvider = 'quickchannel';
  }

  const acceptedCookies = !!Number(cookies[`confirm_${VideoProvider}`]);

  let videoID = null;
  let listID = null;

  if (data?.video) {
    if (data.video?.match('youtu')) {
      if (!placeholder) {
        if (acceptedCookies) {
          //load video preview image from youtube
          if (data.video?.match('list')) {
            listID = data?.video?.match(/^.*\?list=(.*)|^.*&list=(.*)$/)?.[1];
          } else {
            videoID = data.video?.match(/.be\//)
              ? data.video?.match(/^.*\.be\/(.*)/)?.[1]
              : data.video?.match(/^.*\?v=(.*)$/)?.[1];
          }
          placeholder =
            'https://img.youtube.com/vi/' + videoID + '/sddefault.jpg';
        } else {
          placeholder = fallbackVideoImage;
        }
      }
      videoID = data.video?.match(/.be\//)
        ? data?.video?.match(/^.*\.be\/(.*)/)?.[1]
        : data?.video?.match(/^.*\?v=(.*)$/)?.[1];
    } else if (data.video?.match('vimeo')) {
      videoID = data?.video?.match(/^.*\.com\/(.*)/)?.[1];
      if (!placeholder) {
        if (acceptedCookies) {
          placeholder = 'https://vumbnail.com/' + videoID + '.jpg';
        } else {
          placeholder = fallbackVideoImage;
        }
      }
    }
  }

  const ref = createRef();
  const onKeyDown = (e) => {
    if (e.nativeEvent.keyCode === 13) {
      ref.current.handleClick();
      setActive(true);
    }
  };

  const handleClick = (e) => {
    setActive(true);
  };

  const embedSettings = {
    placeholder: placeholder,
    autoplay: false,
    aspectRatio: '16:9',
    tabIndex: 0,
    onKeyPress: onKeyDown,
    ref: ref,
  };

  return (
    <div className={cx('block dasdlr', className)}>
      <div className="block-container">
        <div className="top video">
          {data.video && (
            <div>
              {data.video?.match('youtu') ? (
                <>
                  {data.video?.match('list') ? (
                    <Embed
                      url={`https://www.youtube.com/embed/videoseries?list=${listID}`}
                      {...embedSettings}
                      active={active}
                    />
                  ) : videoID ? (
                    <Embed
                      id={videoID}
                      source="youtube"
                      {...embedSettings}
                      active={active}
                    />
                  ) : (
                    <div className="ui 16:9 embed ">
                      <img
                        src={placeholder}
                        alt="video placeholder"
                        className="placeholder"
                        loading="lazy"
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {data.video?.match('vimeo') ? (
                    <Embed
                      id={videoID}
                      source="vimeo"
                      {...embedSettings}
                      active={active}
                    />
                  ) : (
                    <>
                      {data.video?.match('.mp4') ? (
                        // eslint-disable-next-line jsx-a11y/media-has-caption
                        <video
                          src={
                            isInternalURL(data.video)
                              ? `${flattenToAppURL(data.video)}/@@download/file`
                              : data.video
                          }
                          controls
                          poster={placeholder}
                          type="video/mp4"
                        />
                      ) : isEditMode ? (
                        <div>
                          <Message>
                            <center>
                              <FormattedMessage
                                id="Please enter a valid URL by deleting the block and adding a new Das DLR block."
                                defaultMessage="Please enter a valid URL by deleting the block and adding a new Das DLR block."
                              />
                            </center>
                          </Message>
                        </div>
                      ) : (
                        <div className="invalidVideoFormat" />
                      )}
                    </>
                  )}
                </>
              )}
              {videoID && (
                <div className={cx('inner-controls', { disabled: active })}>
                  <div className="inner">
                    <div>
                      {data?.video_headtitle && (
                        <span className="headtitle">
                          {' '}
                          {data.video_headtitle}
                        </span>
                      )}
                      {data?.video_title && <p>{data?.video_title}</p>}
                      {data?.video_button_link &&
                        data.video_button_link?.[0] &&
                        acceptedCookies && (
                          <div className="button-wrapper">
                            <UniversalLink
                              href={
                                isEditMode
                                  ? null
                                  : isInternalURL(
                                      data.video_button_link?.[0]['@id'],
                                    )
                                  ? flattenToAppURL(
                                      data.video_button_link?.[0]['@id'],
                                    )
                                  : data.video_button_link?.[0]['@id']
                              }
                              target={
                                data?.video_openLinkInNewTab ? '_blank' : null
                              }
                            >
                              <button>
                                {data?.video_button_text
                                  ? data.video_button_text
                                  : data.video_button_link?.[0].title}
                              </button>
                            </UniversalLink>
                          </div>
                        )}
                    </div>
                    <div>
                      {acceptedCookies && (
                        <button
                          className="play"
                          onClick={(e) => handleClick()}
                          aria-label="play"
                        ></button>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {!acceptedCookies && VideoProvider && (
                <CookieConsentButtons module={VideoProvider} />
              )}
            </div>
          )}
        </div>
        <Grid className="bottom teasers" columns="equal">
          <Grid.Column className="left">
            <div className="headline">
              <h2 className={cx({ novid: !videoID })}>{data?.headline_left}</h2>
            </div>
            <Grid>
              <div className="teaser top">
                <h3 className="title">{data?.title_top_left}</h3>
                <p className="description">{data?.description_top_left}</p>
                <UniversalLink
                  href={
                    isEditMode
                      ? null
                      : data?.href_top_left?.[0]?.['@id']
                      ? isInternalURL(data.href_top_left[0]['@id'])
                        ? flattenToAppURL(data.href_top_left[0]['@id'])
                        : data.href_top_left[0]['@id']
                      : null
                  }
                  target={data?.openLinkInNewTab_top_left ? '_blank' : null}
                >
                  <button>
                    {data?.button_text_top_left
                      ? data.button_text_top_left
                      : data?.title_top_left}
                  </button>
                </UniversalLink>
                <div className="divider"></div>
              </div>

              <div className="teaser bottom">
                <h3 className="title">{data?.title_bottom_left}</h3>
                <p className="description">{data?.description_bottom_left}</p>
                <UniversalLink
                  href={
                    isEditMode
                      ? null
                      : data?.href_bottom_left?.[0]?.['@id']
                      ? isInternalURL(data.href_bottom_left[0]['@id'])
                        ? flattenToAppURL(data.href_bottom_left[0]['@id'])
                        : data.href_bottom_left[0]['@id']
                      : null
                  }
                  target={data?.openLinkInNewTab_bottom_left ? '_blank' : null}
                >
                  <button>
                    {data?.button_text_bottom_left
                      ? data.button_text_bottom_left
                      : data?.title_bottom_left}
                  </button>
                </UniversalLink>
              </div>
            </Grid>
          </Grid.Column>

          <Grid.Column className="right">
            <div className="headline">
              <h2 className={cx({ novid: !videoID })}>
                {data?.headline_right}
              </h2>
            </div>

            <div className="teaser center">
              <MaybeWrap
                condition={!isEditMode}
                as={UniversalLink}
                item={data?.href_right?.[0]}
                target={
                  data.openLinkInNewTab_right ||
                  (openExternalLinkInNewTab &&
                    !isInternalURL(data?.href_right?.[0]?.['@id']))
                    ? '_blank'
                    : null
                }
              >
                {((data?.preview_image_right &&
                  data?.preview_image_right?.length > 0) ||
                  data?.href_right?.[0]?.image_field) && (
                  <div className="image-wrapper">
                    <PreviewImg
                      previewImage={data?.preview_image_right?.[0]}
                      href={data?.href_right?.[0]}
                      scaleName="teaser"
                      style={{ width: '100%', height: 'auto' }}
                    />
                  </div>
                )}
                <div className="whitebox">
                  <h3 className="title">{data?.title_right}</h3>
                  <p className="description">{data?.description_right}</p>
                </div>
              </MaybeWrap>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </div>
  );
};

export default DasDLRBlockView;
