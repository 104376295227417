import React from 'react';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';

const EventDates = (props) => {
  const { content } = props;
  const language = useSelector((state) => state.intl.locale);
  const start = new Date(content.start);
  const end = content.end ? new Date(content.end) : null;

  // If `content` is a full event object,
  // we have `whole_day` and `open_end`.
  // If it's from the catalog,
  // we have to guess this based on the times 00:00 and 23:59.
  const isWholeDay =
    content.whole_day ||
    (start.toLocaleTimeString('de', { timeZone: 'Europe/Berlin' }) ===
      '00:00:00' &&
      end &&
      end.toLocaleTimeString('de', { timeZone: 'Europe/Berlin' }) ===
        '23:59:00');
  const isOpenEnd =
    !end ||
    end.toLocaleTimeString('de', { timeZone: 'Europe/Berlin' }) === '23:59:00';

  const formatter = isWholeDay
    ? new Intl.DateTimeFormat(language, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'Europe/Berlin',
      })
    : new Intl.DateTimeFormat(language, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
  let formattedDate;
  if (isOpenEnd) {
    formattedDate = formatter.format(start) + (language === 'de' ? ' Uhr' : '');
  } else {
    formattedDate = formatter.formatRange(start, end);
  }
  return <span className="day">{formattedDate}</span>;
};

export default injectIntl(EventDates);
