import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { ConditionalLink, UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { FormattedDate } from '@plone/volto/components';

import { PreviewImg, useTags } from 'volto-dlr/helpers';

const BlogListingTemplate = ({ items, linkMore, isEditMode }) => {
  const language = useSelector((state) => state.intl.locale);
  let link = null;
  let href = linkMore?.href || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink
        to={flattenToAppURL(href)}
        condition={!isEditMode}
        className="ui button"
      >
        {linkMore?.title || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkMore?.title || href}</a>;
  }

  const tags = useTags();

  return (
    <>
      <div className="blog-listing-template">
        <div className="items">
          {items.map((item) => (
            <div key={item.UID} className="event-item">
              <div className="content">
                <div className="listing-item" key={item['@id']}>
                  <div className="text">
                    <ConditionalLink
                      to={flattenToAppURL(item['@id'])}
                      condition={!isEditMode}
                    >
                      <div className="image-wrapper">
                        <PreviewImg
                          previewImage={item}
                          blurhashOptions={{
                            style: {
                              width: '300px',
                              height: 'auto',
                              alignSelf: 'center',
                            },
                          }}
                        />
                      </div>
                      <div className="listing-body">
                        {item?.['@type'] === 'Post' && (
                          <div className="head-title">
                            {item?.research_domains?.length ? (
                              <>
                                <span className="research_domains">
                                  {item.research_domains.join(', ')}
                                </span>
                                {' | '}
                              </>
                            ) : null}

                            <span className="day">
                              <FormattedDate
                                className="day"
                                date={item?.effective}
                                format={{
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                                }}
                              />
                            </span>
                          </div>
                        )}
                        <h2>{item?.title ? item?.title : item?.id}</h2>
                        <p className="description post">{item?.description}</p>
                      </div>
                    </ConditionalLink>
                  </div>
                  {item?.['@type'] === 'Post' && (
                    <div className="item-footer">
                      {item?.post_authors && item.post_authors.length > 0 && (
                        <div className="authors">
                          <FormattedMessage id="by" defaultMessage="by" />
                          {item?.post_authors.map((author, index) => (
                            <span key={author}>
                              <a
                                className="author"
                                href={`/${language}/blog/${
                                  language === 'de' ? 'autoren' : 'authors'
                                }/${author
                                  .toLowerCase()
                                  .replace(/\s+/g, '-')
                                  .replace(/ä/g, 'ae')
                                  .replace(/ö/g, 'oe')
                                  .replace(/ü/g, 'ue')}`}
                              >{` ${author}`}</a>
                              {index < item?.post_authors.length - 1 && ', '}
                            </span>
                          ))}
                        </div>
                      )}
                      {item?.blog_tags && (
                        <div className="subjects">
                          {item?.blog_tags.map((uid) =>
                            tags[uid] ? (
                              <UniversalLink
                                className="subject"
                                href={tags[uid].url}
                                key={uid}
                              >
                                {tags[uid].title}
                              </UniversalLink>
                            ) : null,
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        {link && <div className="block-footer">{link}</div>}
      </div>
    </>
  );
};
BlogListingTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default BlogListingTemplate;
